<template>
  <div v-if="!isMenuVisible" class="bg-gray3 lg:hidden">
    <div class="container flex justify-between items-center py-4 !pl-0 gap-1">
      <div class="flex items-center">
        <button :title="$t('close')" class="w-12 h-12" @click="onClose">
          <Icon name="close" color="gray" size="sm" />
        </button>

        <NuxtLink :to="localePath('/')" class="inline-block w-fit text-center mx-2">
          <img
            :src="logoSVG"
            alt="InDoc EDGE Logo"
            :width="512"
            :height="512"
            class="block h-auto w-14"
          />
        </NuxtLink>

        <div class="inline-flex flex-col">
          <span class="text-sm text-white">{{ $t('appBanner.title') }}</span>
          <span class="text-xs text-gray1">{{ $t('appBanner.subtitle') }}</span>
        </div>
      </div>
      <div class="flex-shrink-0 flex-grow-0 flex-auto">
        <Button size="sm" :label="$t('appBanner.button')" @click="onRedirect" />
        <div class="text-xs text-gray1 text-center mt-1">
          {{ $t('appBanner.underButton') }}
        </div>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import logoSVG from '~/assets/images/logo-tertiary.svg'
import { MobileAppUrl } from '~/types'

const isMenuVisible = useState('menu')
const localePath = useLocalePath()

const emit = defineEmits(['user-action:click'])

function onClose() {
  emit('user-action:click')
}

function onRedirect() {
  emit('user-action:click')

  if (/Android/i.test(navigator.userAgent)) {
    window.location.href = MobileAppUrl.GooglePlay
  } else if (/iPhone|iPad|iPod/i.test(navigator.userAgent)) {
    window.location.href = MobileAppUrl.AppleStore
  } else {
    window.location.href = 'https://indocedge.com'
  }
}
</script>
