<template>
  <div
    class="group 2xl:relative w-full max-w-lg lg:mx-3 lg:w-fit"
    @mouseenter="submenuShow"
    @mouseleave="submenuHide"
  >
    <component
      :is="data.reference !== null ? NuxtLink : data.externalLink ? NuxtLink : 'button'"
      :to="data.reference ? localePathByType('PageRecord', data.reference.slug) : data.externalLink"
      class="relative my-2 block w-fit px-4 py-3 lg:my-0 lg:pl-0 lg:pt-0 lg:pb-10"
      :class="[
        {
          'text-primary': data.reference !== null && $route.params.slug === data.reference.slug
        },
        { 'pr-6 ': Array.isArray(data.children) && data.children.length },
        data.reference !== null || data.externalLink
          ? 'cursor-pointer'
          : Array.isArray(data.children) && data.children.length
          ? 'cursor-pointer'
          : 'cursor-auto'
      ]"
      @click="onClick(data)"
    >
      <span
        class="absolute bottom-0 left-0 h-1 transform-gpu bg-primary transition-all duration-300 ease-back-out"
        :class="isSubmenuVisible ? 'w-full' : ' w-0'"
      ></span>
      {{ data.title }}
      <Icon
        v-if="Array.isArray(data.children) && data.children.length"
        name="chevron-filled-down"
        size="sm"
        :color="isSubmenuVisible ? '#D70036' : 'black'"
        :class="[isSubmenuVisible ? 'rotate-180' : 'rotate-0']"
        class="pointer-events-none absolute right-0 top-4 lg:top-0"
      />
    </component>
    <Transition name="menu">
      <div
        v-show="isSubmenuVisible"
        class="relative w-full lg:absolute lg:top-full lg:-left-6 lg:shadow-2xl"
        :class="
          Array.isArray(data.blocks) && data.blocks.length
            ? 'lg:w-[880px] xl:w-[968px]'
            : 'lg:w-[624px]'
        "
      >
        <div class="lg:flex">
          <ul
            v-if="Array.isArray(data.children) && data.children.length"
            class="mb-6 grid grid-cols-6 gap-4 px-4 lg:mb-0 lg:grid-cols-12 lg:gap-6 lg:bg-white lg:p-8 lg:flex-[624px] auto-rows-max"
          >
            <li v-for="item in childrenFiltered" :key="item.id" class="col-span-6">
              <NuxtLink
                :to="
                  item.reference
                    ? localePathByType('PageRecord', item.reference?.slug as string)
                    : item.externalLink
                "
                class="block p-3 transition-colors delay-100"
                :class="{
                  'bg-primary text-white':
                    item.reference !== null && $route.params.slug === item.reference.slug
                }"
                @click="onClickChild"
              >
                <div class="mb-2 text-base-semibold transition-colors flex items-center gap-[6px]">
                  <span class="inline-block whitespace-nowrap">{{ item.title }}</span>
                  <span
                    v-if="item.tag"
                    class="inline-block text-xs text-white bg-green px-2 py-[2px]"
                    >{{ item.tag }}</span
                  >
                </div>
                <div v-if="item.description" class="text-sm">{{ item.description }}</div>
              </NuxtLink>
            </li>
          </ul>
          <div
            v-if="Array.isArray(data.blocks) && data.blocks.length"
            class="hidden md:block lg:flex-[344px]"
          >
            <div class="bg-white2 px-10 py-10 h-full">
              <div v-if="data.blocks[0].title" class="text-base-semibold uppercase text-black mb-7">
                {{ data.blocks[0].title }}
              </div>
              <ul v-if="Array.isArray(data.blocks[0].reference) && data.blocks[0].reference.length">
                <li v-for="item in data.blocks[0].reference" :key="item.title">
                  <NuxtLink
                    noPrefetch
                    :to="localePathByType(item?.__typename as string, item?.slug as string)"
                    class="block transition-colors text-black hover:border-primary hover:text-primary"
                    :class="{
                      'border-primary': item.reference !== null && $route.params.slug === item.slug
                    }"
                    @click="onClickChild"
                  >
                    <Image
                      v-if="data.blocks[0].image"
                      loading="lazy"
                      class="mb-6"
                      :sizes="data.blocks[0].image.responsiveImage?.sizes"
                      :src="
                        data.blocks[0].image.responsiveImage
                          ? data.blocks[0].image.responsiveImage?.src
                          : data.blocks[0].image.url
                      "
                      :srcset="
                        data.blocks[0].image.responsiveImage
                          ? data.blocks[0].image.responsiveImage?.webpSrcSet
                          : undefined
                      "
                      :width="
                        data.blocks[0].image.responsiveImage
                          ? data.blocks[0].image.responsiveImage.width
                          : data.blocks[0].width
                      "
                      :height="
                        data.blocks[0].image.responsiveImage
                          ? data.blocks[0].image.responsiveImage.height
                          : data.blocks[0].height
                      "
                      :alt="data.blocks[0].image.alt ? data.blocks[0].image.alt : ''"
                    />

                    <div v-if="item.category" class="flex justify-between items-center mb-3">
                      <div
                        class="bg-black text-white px-2 text-sm h-8 flex items-center justify-center"
                      >
                        {{ item.category[0].name }}
                      </div>
                      <div class="bg-red-50 w-8 h-8 rounded-full flex items-center justify-center">
                        <Icon name="arrow-right" size="xs" color="white" />
                      </div>
                    </div>

                    <div class="text-base-semibold mb-3">
                      {{ data.blocks[0].blockTitle ?? item.title }}
                    </div>
                    <div v-if="data.blocks[0].description" class="text-sm text-gray3">
                      {{ data.blocks[0].description }}
                    </div>
                    <div
                      v-if="item.__typename === 'PageRecord'"
                      class="w-8 h-8 flex items-center justify-center border-2 border-black rounded-full mt-4"
                    >
                      <Icon name="arrow-right-long" size="xs" color="black" />
                    </div>
                  </NuxtLink>
                </li>
              </ul>
              <Button
                v-else-if="Array.isArray(data.blocks[0].buttons) && data.blocks[0].buttons.length"
                :label="data.blocks[0].buttons[0].title"
                :to="
                  data.blocks[0].buttons[0].pageLink !== null
                    ? localePathByType(
                        data.blocks[0].buttons[0].pageLink.__typename,
                        data.blocks[0].buttons[0].pageLink.slug
                      )
                    : data.blocks[0].buttons[0].externalLink
                "
                :target="data.blocks[0].buttons[0].externalLink && '_blank'"
                isType="text"
              />
            </div>
          </div>
        </div>
      </div>
    </Transition>
  </div>
</template>

<script setup lang="ts">
const NuxtLink = resolveComponent('NuxtLink')
const { isSubmenuVisible, menuHide, submenuShow, submenuHide, submenuToggle } = useMenu()

const { localePathByType } = useLocalePathByType()

const props = defineProps({
  data: {
    type: Object,
    default: () => {}
  },
  idx: {
    type: Number
  }
})

// Extract only published translations
const childrenFiltered = computed(() =>
  props.data.children.filter(x => x.publishTranslation === true)
)

function onClick(data: object) {
  if (Array.isArray(data.children) && data.children.length) {
    submenuToggle()
  } else {
    menuHide()
  }
}

function onClickChild() {
  submenuHide()
  menuHide()
}
</script>
