<template>
  <header
    class="sticky top-0 z-10 h-18 bg-white opacity-100 transition-all duration-300 lg:h-[104px]"
    :class="isBoxShadow && 'shadow-header'"
  >
    <MenuBackDrop />
    <Transition name="fade">
      <div class="container relative h-full">
        <Logo class="left-4 top-[26px] z-1 lg:top-[38px]" :class="isMenuVisible ? 'fixed' : 'absolute'" @click="menuHide" />
        <slot />
      </div>
    </Transition>
    <MenuButton />
  </header>
</template>

<script setup lang="ts">
defineProps({
  data: {
    type: Object,
    default: () => {}
  }
})

const { menuHide } = useMenu()

const isBoxShadow = ref(false)
const isMenuVisible = useState('menu')

const onScroll = () => {
  if (process.client) {
    const st = window.scrollY || document.documentElement.scrollTop

    if (st > 104) {
      isBoxShadow.value = true
    } else {
      isBoxShadow.value = false
    }
  }
}

onMounted(() => {
  window.addEventListener('scroll', onScroll)
})

onUnmounted(() => {
  window.removeEventListener('scroll', onScroll)
})
</script>
