<template>
  <nav
    v-show="isMenuVisible"
    class="left-0 top-0 h-screen w-full pt-16 lg:left-40 lg:top-10 lg:!block lg:h-auto lg:w-fit lg:p-0"
    :class="isMenuVisible ? 'fixed' : 'absolute'"
  >
    <div
      class="flex h-full flex-col overflow-scroll pb-60 lg:h-auto lg:flex-row lg:overflow-visible lg:p-0"
    >
      <MenuListItem v-for="(item, idx) in parents" :key="idx" :idx="idx" :data="item" />
    </div>
  </nav>
</template>

<script setup lang="ts">
import screens from '#tailwind-config/theme/screens'
import { MenuRecord } from '~/types/generated'

const props = defineProps({
  data: {
    type: Array as PropType<MenuRecord[]>,
    default: () => []
  }
})

const { menuHide } = useMenu()
const isMenuVisible = useState('menu')
const breakpoints = useBreakpoints(screens)
const isScreenLg = breakpoints.greaterOrEqual('lg')

// Get the top level menu items
const parents = computed(() => props.data.filter(item => item.parent === null))

// Handle menu on resize
const handleResize = useDebounceFn(() => {
  if (isScreenLg.value && isMenuVisible.value) {
    menuHide()
  }
}, 400)

onMounted(() => {
  window.addEventListener('resize', handleResize)
})

onUnmounted(() => {
  window.removeEventListener('resize', handleResize)
})
</script>
