<template>
  <ClientOnly>
    <Transition name="slideY">
      <div
        v-if="data.active && isContentChanged && eventVisibility && route.params.slug !== data.button[0].pageLink.slug"
        class="py-5 text-base"
        :class="[
          data.variant === BACKGROUND_VARIANTS.VIOLET && 'bg-violet-20 text-black',
          data.variant === BACKGROUND_VARIANTS.GREEN && 'bg-green text-white',
          data.variant === BACKGROUND_VARIANTS.BLUE && 'bg-blue-60 text-white'
        ]"
      >
        <div class="container relative flex flex-wrap items-center gap-2 pr-8">
          <div v-html="data.text"></div>
          <Button
            :label="data.button[0].title"
            :to="
              data.button.pageLink !== null
                ? localePathByType(
                    data.button[0].pageLink?.__typename as string,
                    data.button[0].pageLink?.slug as string
                  )
                : data.button[0].externalLink
            "
            :target="data.button[0].externalLink && '_blank'"
            :isType="'custom'"
            class="font-semibold"
          />
          <div class="absolute right-4 top-0">
            <button @click="handleButtonClick">
              <Icon
                name="close"
                :color="data.variant === BACKGROUND_VARIANTS.VIOLET ? theme.colors.black : 'white'"
              />
            </button>
          </div>
        </div>
      </div>
    </Transition>
  </ClientOnly>
</template>

<script setup lang="ts">
import { theme } from '#tailwind-config'

const { localePathByType } = useLocalePathByType()
const route = useRoute()

enum BACKGROUND_VARIANTS {
  VIOLET = 'Violet',
  GREEN = 'Green',
  BLUE = 'Blue'
}

const props = defineProps({
  data: {
    type: Object,
    default: () => {}
  }
})

const isContentChanged = ref(true)
const eventVisibility = ref(true)

if (process.client) {
  if (localStorage.getItem('event-announcer')) {
    isContentChanged.value = localStorage.getItem('event-announcer') !== props.data.text
  }
}

function handleButtonClick() {
  localStorage.setItem('event-announcer', props.data.text)
  eventVisibility.value = false
}
</script>
