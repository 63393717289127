<template>
  <NuxtLink :to="localePath('/')" class="inline-block w-fit text-center">
    <img
      :src="logoSVG"
      alt="Nuxt Logo"
      :width="109"
      :height="24"
      class="block h-auto w-[78px] lg:w-[108px]"
    />
  </NuxtLink>
</template>

<script setup lang="ts">
import logoSVG from '~/assets/images/logo.svg'

const localePath = useLocalePath()
</script>
