<template>
  <div
    v-show="isMenuVisible || isLg"
    class="fixed bottom-0 left-0 w-full bg-white px-4 lg:absolute lg:bottom-auto lg:left-auto lg:right-0 lg:top-8 lg:!flex lg:w-fit lg:bg-transparent"
  >
    <div v-if="data" class="flex w-full max-w-xs flex-col lg:w-fit lg:max-w-none lg:flex-row">
      <div class="pb-4 lg:pb-0 lg:pl-8" v-for="item in data" :key="item.id">
        <Button
          :label="item.title"
          :to="
            item.reference
              ? localePathByType('PageRecord', item.reference?.slug as string)
              : item.externalLink
          "
          :target="item.externalLink && '_blank'"
          :isType="item.primary ? 'primary' : 'custom'"
          :class="
            item.primary
              ? '!block !w-full lg:!h-10'
              : 'group relative flex !h-10 w-fit flex-col justify-center !text-left lg:mx-auto lg:mt-2 lg:!block lg:!h-16 lg:pb-10 lg:!text-center'
          "
          noPrefetch
          @click="menuHide"
        >
          <span
            class="absolute bottom-0 left-0 hidden h-1 w-0 transform-gpu bg-primary transition-all duration-300 ease-back-out group-hover:w-full lg:block"
          ></span
        ></Button>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { MenuCtaRecord } from '~/types/generated'
import { breakpointsTailwind } from '@vueuse/core'
const breakpoints = useBreakpoints(breakpointsTailwind)

const props = defineProps({
  data: {
    type: Array as PropType<MenuCtaRecord[]>,
    default: () => []
  }
})

const { localePathByType } = useLocalePathByType()

const { menuHide } = useMenu()

const isMenuVisible = useState('menu')
const isLg = breakpoints.greater('lg')
const isVisible = ref(false)

onMounted(() => {
  useTimeoutFn(() => {
    isVisible.value = true
  }, 100)
})
</script>
