<template>
  <Html :lang="head.htmlAttrs.lang" :dir="head.htmlAttrs.dir">
    <Head>
      <Title>{{ title }}</Title>
      <template v-for="link in head.link" :key="link.id">
        <Link :id="link.id" :rel="link.rel" :href="link.href" :hreflang="link.hreflang" />
      </template>
      <template v-for="meta in head.meta" :key="meta.id">
        <Meta :id="meta.id" :property="meta.property" :content="meta.content" />
      </template>
    </Head>
    <Body>
      <div>
        <ClientOnly>
          <SmartAppBanner v-if="!bannerVisited" @user-action:click="bannerHandle()" />
        </ClientOnly>
        <EventAnnouncer :data="data.eventAnnouncer" />
        <Header>
          <MenuList :data="data.allMenus" />
          <MenuCTA :data="data.allMenuCtas" />
        </Header>
        <main>
          <slot />
        </main>
        <LazyFooter :data="data" />
        <LazyCookieBanner v-if="loadCookieBanner" />
      </div>
    </Body>
  </Html>
</template>

<script setup>
const route = useRoute()
const head = useLocaleHead({
  addDirAttribute: true,
  identifierAttribute: 'id',
  addSeoAttributes: true
})

defineProps({
  data: {
    type: Object,
    default: () => {}
  }
})

const isDelayed = ref(false)

const { t } = useI18n()
const title = computed(() => t('layouts.title', { title: t(route.meta.title ?? 'TBD') }))

// Delay loading of cookie banner
const loadCookieBanner = ref(false)
onMounted(() => {
  useTimeoutFn(() => {
    loadCookieBanner.value = true
  }, 3000)
})

const bannerVisited = ref()

if (process.client) {
  bannerVisited.value = localStorage.getItem('smart-banner')
}

function bannerHandle() {
  bannerVisited.value = true
  localStorage.setItem('smart-banner', JSON.stringify('true'))
}

onMounted(() => {
  useTimeoutFn(() => {
    isDelayed.value = true
  }, 3000)
})
</script>
